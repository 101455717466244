.list-group-horizontal-md.gameProviderList {
  overflow-x: auto;
  white-space: nowrap;
  background: #8080801a;
  padding: 5px 0;  
}

.list-group-item.gameProviderItem {
  padding: 0;
  width: 80px;
  margin: 0 0 0 5px;
  border: 0;
}

.gameProviderMainTitle {
  padding: 10px 0;
}